import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { white } from '@ecomm/colors';
import BottomBar from './BottomBar';
import Links from './Links';
import PrivacyPolicy from './PrivacyPolicy';
import SocialMedia from './SocialMedia';

export type LayoutProps = {
  footerStyle?: 'normal' | 'compact';
};

const Footer: React.FC<React.PropsWithChildren<LayoutProps>> = ({
  footerStyle = 'normal',
}) => {
  const showMedia = footerStyle == 'normal';

  return (
    <footer data-test-id="footer">
      {showMedia && (
        <Container>
          <Links />
          <MediaContainer>
            <SocialMedia />
            <PrivacyPolicy />
          </MediaContainer>
        </Container>
      )}
      <BottomBar />
    </footer>
  );
};

export default Footer;

const Container = styled.div`
  background: ${white};
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  padding-left: 20px;
  justify-content: space-between;

  ${media.tabletXLarge`
    padding-left: 60px;
  `}

  ${media.desktopLarge`
    padding-top: 60px;
    padding-left: 90px;
    flex-direction: row;
  `}
`;

const MediaContainer = styled.div`
  margin-bottom: 45px;
  width: 100%;
  section {
    margin-top: 0px;
  }

  ${media.tablet`
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    margin-top: 40px;
  `}

  ${media.tabletXLarge`
    margin-top: 0px;
    margin-bottom: 40px;
  `}

  ${media.desktopLarge`
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 0;
    margin-bottom: 60px;
    max-width: 386px;
    min-width: 235px;
    width: 100%;
  `}
`;
