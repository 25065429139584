import { brand, Support, grey } from '@pelotoncycle/design-system';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Links } from '@peloton/links';
import { useOneTrust } from '@peloton/onetrust/useOneTrust';
import { media } from '@peloton/styles';
import type { LinkProps } from '@ecomm/links';
import { Link } from '@ecomm/links';
import { useStudioKeyValueData } from '@studio/copy';

type ToggleProps = {
  ccpaNotice?: boolean;
  accessibilityPage?: boolean;
  newLocaleNav?: boolean;
};

type Props = ToggleProps & {
  accessibilityLinkText?: string;
  caPrivacyNoticeText: React.ReactNode;
  copyrightText: React.ReactNode;
  ipPolicyText: React.ReactNode;
  membershipTermsText: React.ReactNode;
  privacyPolicyText: React.ReactNode;
  termsOfServiceText: React.ReactNode;
};

type StylingProps = {
  leftAligned: boolean;
};

const FooterBar: React.FC<React.PropsWithChildren<Props>> = ({
  accessibilityLinkText = '',
  accessibilityPage = false,
  caPrivacyNoticeText,
  ccpaNotice = false,
  copyrightText,
  ipPolicyText,
  membershipTermsText,
  newLocaleNav = false,
  privacyPolicyText,
  termsOfServiceText,
}) => {
  const cookieCopy = useStudioKeyValueData('footer.oneTrust.cookie');
  const doNotSellCopy = useStudioKeyValueData('footer.oneTrust.DoNotSell');
  const healthDataPolicy = useStudioKeyValueData('studioSite.footer.healthDataPolicy');
  const {
    toggleInfoDisplay,
    isReady: isOneTrustReady,
    isDoNotSellEnabled,
    doNotSellLink,
    geolocationData,
  } = useOneTrust();
  const [state, setState] = useState<string | undefined>();

  useEffect(() => {
    const location = geolocationData;
    setState(location?.state);
  }, [geolocationData]);

  return (
    <Container leftAligned={newLocaleNav} data-test-id="bottomBar">
      <TextContainer>
        <LinkContainer>
          <FooterLink to={Links.privacy}>
            <FooterText is="span" size="small">
              {privacyPolicyText}
            </FooterText>
          </FooterLink>
          <FooterLink to={Links.tos}>
            <FooterText is="span" size="small">
              {termsOfServiceText}
            </FooterText>
          </FooterLink>
          <FooterLink to={Links.membershipTerms}>
            <FooterText is="span" size="small">
              {membershipTermsText}
            </FooterText>
          </FooterLink>
          {ccpaNotice && (
            <>
              <FooterLink to={Links.ipPolicy}>
                <FooterText is="span" size="small">
                  {ipPolicyText}
                </FooterText>
              </FooterLink>
              <FooterLink to={Links.caPrivacyNotice}>
                <FooterText is="span" size="small">
                  {caPrivacyNoticeText}
                </FooterText>
              </FooterLink>
            </>
          )}
          {isOneTrustReady && (
            <FooterLink to="#" onClick={() => toggleInfoDisplay()}>
              <FooterText is="span" size="small">
                {cookieCopy}
              </FooterText>
            </FooterLink>
          )}
          {isDoNotSellEnabled && (
            <FooterLink to={doNotSellLink} target="_blank" rel="noopener">
              <FooterText is="span" size="small">
                {doNotSellCopy}
              </FooterText>
            </FooterLink>
          )}
          {accessibilityPage && (
            <FooterLink to={Links.accessibility}>
              <FooterText is="span" size="small">
                {accessibilityLinkText}
              </FooterText>
            </FooterLink>
          )}
          {state === 'WA' && (
            <FooterLink to={Links.healthDataPolicy}>
              <FooterText is="span" size="small">
                {healthDataPolicy}
              </FooterText>
            </FooterLink>
          )}
          <div>
            <FooterTextCopyright is="span" size="small">
              {copyrightText}
            </FooterTextCopyright>
          </div>
        </LinkContainer>
      </TextContainer>
    </Container>
  );
};

export default FooterBar;

const Container = styled.div`
  color: ${brand.darkest};
  padding: 17px 20px;
  width: 100%;
  background: ${brand.light};
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: ${({ leftAligned }: StylingProps) => (leftAligned ? 'start' : 'center')};

  ${media.tablet`
    justify-content: ${({ leftAligned }: StylingProps) =>
      leftAligned ? 'normal' : 'center'};
    padding: ${({ leftAligned }: StylingProps) =>
      leftAligned ? '17px 20px 18px 20px' : '17px 30px 18px'};
  `}

  ${media.tabletLarge`
    padding: ${({ leftAligned }: StylingProps) =>
      leftAligned ? '17px 20px 18px 20px' : '17px 30px 18px'};
  `}

  ${media.desktop`
    padding: ${({ leftAligned }: StylingProps) =>
      leftAligned ? '17px 50px 18px 50px' : '17px 30px 18px'};
  `}
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const footerTextMarginStyles = css`
  margin: 0 30px 5px 0;

  ${media.tablet`
    margin-bottom: 0;
  `}
`;

const FooterLink = styled(Link)`
  color: ${grey[70]};
  ${footerTextMarginStyles}
  text-decoration: none;

  :last-child {
    margin-right: 0;
  }
` as React.ComponentType<React.PropsWithChildren<LinkProps>>;

const FooterText = styled(Support)`
  ${footerTextMarginStyles}
`;

const FooterTextCopyright = styled(FooterText)`
  color: ${grey[70]};
  align-self: center;
`;

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;
