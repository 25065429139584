import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Layout } from '@studio/layout';
import type { RegionSlug } from '@studio/regions';
import { TeamUp } from '@studio/teamup';

interface ITeamUpScheduleProps {
  region: RegionSlug;
}

export const TeamUpScheduleView: React.FC<
  React.PropsWithChildren<ITeamUpScheduleProps>
> = ({ region }: ITeamUpScheduleProps) => {
  return (
    <Layout footerStyle="compact">
      <TeamUp region={region} />
    </Layout>
  );
};

export default compose(withRouter)(TeamUpScheduleView);
