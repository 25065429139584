import type { TypePolicy } from '@apollo/client';
import {
  ApolloClient as ApolloClientV3,
  InMemoryCache as InMemoryCacheV3,
  HttpLink as HttpLinkV3,
} from '@apollo/client';
// Since this is only a type it should be okay in this isomorphic code
import type { Locale } from '@peloton/internationalize';
import { CONTENTFUL_TOKEN } from '@ecomm/contentful';
import { URL, QUERY_TYPENAME_MAP } from './config';
import { CopyQuery } from './documents.generated';
import generatedFragmentType from './fragmentTypes.generatedV3';
import data from './static';

type Options = {
  locale: Locale;
  preview: boolean;
  tag: string;
};

const toTypePolicy = (
  field: string,
  map: typeof QUERY_TYPENAME_MAP,
): TypePolicy['fields'] => ({
  [field]: {
    read: (_, { args, toReference }) =>
      // TS error to be fixed during Apollo 3 migration
      // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
      // @ts-ignore
      toReference({ __typename: map[field], key: args?.id, url: args?.url }),
  },
});

const toQueryTypePolicies = (map: typeof QUERY_TYPENAME_MAP) =>
  Object.keys(map).reduce(
    (acc, curr) => ({
      ...acc,
      ...toTypePolicy(curr, map),
    }),
    {},
  );

const toTypePolicies = (map: typeof QUERY_TYPENAME_MAP) =>
  Object.values(map).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        // @ts-expect-error
        keyFields: obj => (obj.key ? ['key'] : []),
      },
    }),
    {},
  );

export const toClientV3 = ({ locale, preview, tag }: Options) => {
  const link = new HttpLinkV3({
    uri: URL,
    headers: {
      Authorization: `Bearer ${CONTENTFUL_TOKEN}`,
    },
  });

  const cache = new InMemoryCacheV3({
    possibleTypes: generatedFragmentType.possibleTypes,
    typePolicies: {
      Query: {
        fields: toQueryTypePolicies(QUERY_TYPENAME_MAP),
      },
      Asset: {
        keyFields: ['url'],
      },
      ...toTypePolicies(QUERY_TYPENAME_MAP),
    },
  });

  const client = new ApolloClientV3({
    cache,
    link,
    defaultOptions: {
      query: {
        variables: {
          locale,
          preview,
          tag,
        },
      },
    },
  });

  client.writeQuery({
    query: CopyQuery,
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    data: data[locale].data,
  });

  return client;
};

export default toClientV3;
