import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import React from 'react';
import type { Locale } from '@peloton/internationalize';
import { useStudioOAuth } from '@studio/auth';
import datafileDev from './datafile-dev.json';
import datafileProd from './datafile-prod.json';
import env from './env';
import useClientIP from './useClientIP';

function Optimizely({
  children,
  locale,
  isProd,
}: {
  children: React.ReactNode;
  locale: Locale;
  isProd: boolean;
}) {
  const optimizelyInstance = createInstance({
    sdkKey: isProd ? env.prod : env.dev,
    datafile: isProd ? datafileProd : datafileDev,
  });

  const queryParams = new URLSearchParams(window.location.search);
  const teamupProd = queryParams.get('teamupProd');

  if (teamupProd) {
    localStorage.setItem('teamupProd', teamupProd);
  }

  const { isLoading } = useStudioOAuth();
  const clientIp = useClientIP();

  if (isLoading) {
    return null;
  }

  return (
    <OptimizelyProvider
      optimizely={optimizelyInstance}
      user={{
        id: 'userId',
        attributes: {
          studioUserLocale: locale,
          ipAddress: clientIp,
          teamupProd:
            localStorage.getItem('teamupProd') &&
            localStorage.getItem('teamupProd') === 'true'
              ? true
              : false,
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  );
}

export default Optimizely;
