import React from 'react';
import { reportError } from '@peloton/error-reporting';
import type { Locale } from '@peloton/internationalize';
import { toClientV3 as toContentfulClient } from './clientV3';
import { CopyQuery } from './documents.generated';

const useContentfulClient = (preview: boolean, locale: Locale, tag: string) => {
  const client = toContentfulClient({ locale, preview, tag });

  React.useEffect(() => {
    if (preview) {
      client
        .query({
          query: CopyQuery,
          variables: {
            locale,
            preview,
            tag,
          },
        })
        .catch(reportError);
    }
  }, [client, locale, preview, tag]);

  return client;
};

export default useContentfulClient;
