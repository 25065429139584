export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Entry: [
      'ProductDetailsCard',
      'ProductNav',
      'Navigation',
      'ContentAggregate',
      'Promo',
      'PromoBanner',
      'CtaWithDriftInteraction',
      'CtaWithUrlSet',
      'CtaWithUrl',
      'Headband',
      'PromotionalText',
      'ProductBlock',
      'ProductBlockSet',
      'Image',
      'ImageGallery',
      'Video',
      'BreakpointVideo',
      'PromoHero',
      'PromoHeroSet',
      'Hero',
      'MemberReason',
      'MemberReasonSet',
      'ValueProp',
      'ValuePropSet',
      'DigitalCheckout',
      'Section',
      'DigitalPromotion',
      'Forms',
      'KeyValue',
      'Product',
      'Faq',
      'QAndA',
      'LegalText',
      'HeaderBody',
      'SubmissionInput',
      'CaptureFormMessaging',
      'CaptureForm',
      'Shop',
      'ContentByCfu',
      'LongHeaderBodyFootnote',
      'OtdLookup',
      'SvgIcon',
      'MediaSection',
      'Track',
      'HeaderBodyImageWithLink',
      'HeaderBodyImageWithLinkGallery',
      'HeaderBodyImage',
      'HeaderBodyImageGallery',
      'KeyCollection',
      'RegistrationPage',
      'AccountLink',
      'DigitalLink',
      'ExternalLink',
      'StudioSiteLink',
      'WwwLink',
      'ComponentLeadGen',
      'JsonKeyValue',
      'ProductRecommendationsCohort',
      'ProductRecommendationsTile',
      'MarkdownPage',
      'StaticAsset',
      'Metadata',
      'CsTouchscreenProduct',
      'AccountMetadata',
      'PressQuote',
      'QuoteBanner',
      'ContentType2LKn3Xvlo4UCmGcweGaCyM',
      'ContentType1OJlz6NgUyeeaIk8Uci2Ow',
      'BoardOfDirector',
      'ContentType1VcIyFnR8OC2Imaa2Su2W2',
      'ComposeTest',
      'Programs',
      'PackageToSlugMapping',
      'ModuleConfiguration',
      'Cta',
      'Variation',
      'Experiment',
      'AccountKeyRichValue',
      'AvailableProducts',
      'Review',
      'ReviewSection',
      'Modal',
      'CompanyPageText',
      'AccountKeyValue',
      'MarketingSection',
      'AccountUpdatePaymentKeyValue',
      'ContentType3FI5SLqlYIeaKguyIkeyAm',
      'Banner',
    ],
    PromoBannerLinksItem: ['CtaWithDriftInteraction', 'CtaWithUrl'],
    CtaWithUrlSetCtasItem: ['CtaWithDriftInteraction', 'CtaWithUrl'],
    ImageGalleryImagesItem: ['Image', 'Video'],
    PromoHeroBackgroundMedia: ['BreakpointVideo', 'Image'],
    CtaWithUrlLink: [
      'AccountLink',
      'DigitalLink',
      'ExternalLink',
      'StudioSiteLink',
      'WwwLink',
    ],
    ContentAggregateAggregateItem: [
      'ComponentLeadGen',
      'ContentAggregate',
      'CtaWithUrl',
      'CtaWithUrlSet',
      'HeaderBodyImage',
      'HeaderBodyImageWithLink',
      'Image',
      'JsonKeyValue',
      'KeyValue',
      'LegalText',
      'LongHeaderBodyFootnote',
      'Navigation',
      'ProductBlock',
      'ProductBlockSet',
      'ProductRecommendationsCohort',
      'PromotionalText',
      'Shop',
      'SvgIcon',
      'Video',
    ],
    NavigationSubnavsItem: ['CtaWithUrlSet', 'Navigation', 'ProductNav'],
    NavigationAriaLabel: ['KeyValue', 'PromotionalText'],
    NavigationLogoAriaLabel: ['KeyValue', 'PromotionalText'],
    ResourceLink: [
      'AccountKeyRichValueRichValueResourcesBlock',
      'AccountKeyRichValueRichValueResourcesInline',
      'AccountKeyRichValueRichValueResourcesHyperlink',
    ],
    _Node: [],
    _Entity: [
      'AccountKeyRichValue',
      'AccountKeyValue',
      'AccountLink',
      'AccountMetadata',
      'AccountUpdatePaymentKeyValue',
      'AvailableProducts',
      'Banner',
      'BoardOfDirector',
      'BreakpointVideo',
      'CaptureForm',
      'CaptureFormMessaging',
      'CompanyPageText',
      'ComponentLeadGen',
      'ComposeTest',
      'ContentAggregate',
      'ContentByCfu',
      'ContentType1OJlz6NgUyeeaIk8Uci2Ow',
      'ContentType1VcIyFnR8OC2Imaa2Su2W2',
      'ContentType2LKn3Xvlo4UCmGcweGaCyM',
      'ContentType3FI5SLqlYIeaKguyIkeyAm',
      'CsTouchscreenProduct',
      'Cta',
      'CtaWithDriftInteraction',
      'CtaWithUrl',
      'CtaWithUrlSet',
      'DigitalCheckout',
      'DigitalLink',
      'DigitalPromotion',
      'Experiment',
      'ExternalLink',
      'Faq',
      'Forms',
      'Headband',
      'HeaderBody',
      'HeaderBodyImage',
      'HeaderBodyImageGallery',
      'HeaderBodyImageWithLink',
      'HeaderBodyImageWithLinkGallery',
      'Hero',
      'Image',
      'ImageGallery',
      'JsonKeyValue',
      'KeyCollection',
      'KeyValue',
      'LegalText',
      'LongHeaderBodyFootnote',
      'MarkdownPage',
      'MarketingSection',
      'MediaSection',
      'MemberReason',
      'MemberReasonSet',
      'Metadata',
      'Modal',
      'ModuleConfiguration',
      'Navigation',
      'OtdLookup',
      'PackageToSlugMapping',
      'PressQuote',
      'Product',
      'ProductBlock',
      'ProductBlockSet',
      'ProductDetailsCard',
      'ProductNav',
      'ProductRecommendationsCohort',
      'ProductRecommendationsTile',
      'Programs',
      'Promo',
      'PromoBanner',
      'PromoHero',
      'PromoHeroSet',
      'PromotionalText',
      'QAndA',
      'QuoteBanner',
      'RegistrationPage',
      'Review',
      'ReviewSection',
      'Section',
      'Shop',
      'StaticAsset',
      'StudioSiteLink',
      'SubmissionInput',
      'SvgIcon',
      'Track',
      'ValueProp',
      'ValuePropSet',
      'Variation',
      'Video',
      'WwwLink',
    ],
  },
};
export default result;
