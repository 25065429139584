export const FEATURES = [
  'ALWAYS_OFF', // This is a typesafe way to fallback to off
  'annualDigitalSubscriptions',
  'autoApplyReferralCode',
  'apparelCartUI',
  'apparelMigration',
  'buyFlowMigrationR2',
  'buyFlowMigrationR3',
  'caesarSelfAssembly',
  'caesarShowroom',
  'caesarFinancing',
  'checkout_login_modal',
  'cpayCheckout',
  'cpayPrequalify',
  'cpay_enabled',
  'displayRowReviews',
  'februaryAamReferralPulse',
  'giftingCheckoutWithoutAam',
  'graphqlForShopPackages',
  'treadReviewSubmission',
  'treadPlusReviewSubmission',
  'personalizedNotification',
  'newCartPanelAtCheckout',
  'newCartPanelUpsellFeature',
  'oneWellnessDowntime',
  'shopPromos',
  'underMaintenancePage',
  'enableProductAvailablityPanel',
  'luckyDogStrikethroughKillswitch',
  'pluralDescriptor',
  'cosmosOAuthlogin',
  'refurbLandingPage',
  'opc',
  'increasedAllAccessMembershipPrice',
  'caesarSelfAssembly',
  'bundleSavings',
  'treadReviews',
  'treadPlusReviews',
  'web_cooldown',
  'cpo',
  'holidayFinancingTerms',
  'treadFinancing',
  'commercetoolsSimpleAccessoryPDP',
  'commercetoolsCfuPDP',
  'commercetoolscartenabled',
  'account_password_creation',
  'updateKlarnaDEFinancingTerm',
  'isTreadNew',
  'confirmation_qr_code',
  'isProspectsGuestPassEnabled',
  'pageBuilderPDPs',
  'commercetoolsNewPDP',
  'poseidon1',
  'poseidonRefurb',
  'poseidonRental',
  'refurbReviews',
  'tierMobilityEnabled',
  'sso_cfu_checkout',
  'app_signup_updates',
  'sso_register',
  'legacy_cfu_gift_card',
  'commercetoolsGiftCardEnabled',
  'faas_credit_check',
  'newPDPStructuredData',
  'refurbBikePackagePage',
  'commerceToolsPricing',
  'productRecsCommerceTools',
  'showUMichRentalPDP',
  'usePromoContentMgmt',
  'showGiftCardMenu',
  'packagePageRemovalAndCrossShopToggle',
  'fetchUpsellProducts',
  'fetchUpsellSubscriptions',
  'faas_buyout_affirm',
  'oauth_cookie_enabled',
  'newYearsAAMPromoText',
  'gift_cards_concessionary',
  'redirectShopToDeals',
  'dealsPage',
  'commercetoolsRentalsPDP',
  'showProp65Warning',
  'nextAuthLogin',
  'nextAuthLoginCheckout',
  'refurbBikePlus',
  'apolloV3',
  'quick_pay_cfu_checkout_email_overwrite',
  'freeSevenDayTrial',
  'updatedPrivacyNotice',
  'affirmTransactionsAPIKeys',
  'ctCheckoutEnabled',
  'faasBikePlus',
  'zipThirtySixMonthFinancing',
  'guest_pass_updates',
  'socialprospectsexperience',
  'klarnaZeroAprPromotion',
  'adaMigration',
  'spare_parts',
  'faas_debit_card_enabled',
  'truemed_enabled',
  'de3pTransition',
  'special_pricing_phase_1',
  'special_pricing_phase_2',
  'enablegoogleexpresspay',
  'bfcm_2024_referral_pulse',
] as const;

export type OptimizelyToggleId = typeof FEATURES[number];
