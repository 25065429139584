import 'babel-polyfill';
// eslint-disable-next-line import/order
import { GlobalStyles } from '@ecomm/styles'; // must import GlobalStyles first to ensure correct css order
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider, ReactReduxContext } from 'react-redux';
import { reportFocusMethod } from '@peloton/accessibility';
import { load as loadAnalytics, track } from '@peloton/analytics';
import NullAnalytics from '@peloton/analytics/NullAnalytics';
import { makeAnalytics } from '@peloton/analytics/segment';
import { CLIENT_CONTEXT as API_CLIENT } from '@peloton/api';
import { authSaga, userReducer } from '@peloton/auth';
import LogoutCookieProvider from '@peloton/auth/LogoutCookieProvider';
import OauthProvider from '@peloton/auth/OauthProvider';
import { reducer as chatReducer, sagas as driftSagas, ChatStyles } from '@peloton/drift';
import { toApiEnv, toLinkEnv as toWwwLinkEnv, toDigitalLinkEnv } from '@peloton/env';
import { toGlobalLogoutEnv } from '@peloton/env/toGlobalLogoutEnv';
import {
  ERROR_REPORTING_CLIENT_CONTEXT,
  ErrorBoundary,
  ErrorReporterContext,
  configureErrorHandler,
  consoleErrorReporter,
} from '@peloton/error-reporting';
import { extLinkEnvReducer, toExtLinkEnv } from '@peloton/external-links';
import {
  AsyncFallback,
  Provider as GraphQLProvider,
  toClient as toGraphQLClient,
} from '@peloton/graphql';
import { LocaleProvider, toLocaleFromHostname } from '@peloton/internationalize';
import { toStore, history } from '@peloton/redux';
import { ScrollToTop } from '@peloton/scroll';
import { injectFonts } from '@peloton/typography';
import { ApolloProviderV3 } from '@peloton/with-apollo/v3';
import { analyticsSaga } from '@ecomm/analytics';
import CopyXray from '@ecomm/copy-xray/CopyXray';
import {
  computeToggles,
  getQueryToggles,
  reducer as toggles,
  sagas as featureToggleSagas,
} from '@ecomm/feature-toggle';
import fragmentMatcher from '@ecomm/graphql/fragmentMatcher';
import { Status } from '@ecomm/models';
import { PageSpinner } from '@ecomm/spinner';
import vendorKeysReducer from '@ecomm/vendor-keys';
import { client, ClientProvider } from '@studio/api';
import { features, FeatureTogglePanel, Optimizely } from '@studio/bootstrapping';
import { ChatbotContainer as ChatbotLayer } from '@studio/chat';
import { environmentFlags, envReducer, toLinkEnv as toStudioLinkEnv } from '@studio/env';
import Internationalize from '@studio/internationalize';
import { ErrorPage, SimpleErrorPage, DatadogRUMContextProvider } from '@studio/layout';
import { NoIndexMetadataTag } from '@studio/metadata';
import { getKeys } from '@studio/vendor-keys';
import Routes from './Routes';
import { routeTree } from './routeTree';

injectFonts();

// accessibility tool for local development
if (process.env.NODE_ENV === 'development') {
  const axe = require('react-axe');
  axe(React, ReactDOM, 1000);
}

const locale = toLocaleFromHostname(window.location.hostname);

const envFlags = environmentFlags();
const apiEnv = toApiEnv(location.hostname, envFlags);
const globalLogoutEnv = toGlobalLogoutEnv(apiEnv);

const env = {
  extLinkEnv: toExtLinkEnv({
    api: apiEnv,
    digital: toDigitalLinkEnv(envFlags, apiEnv),
    ecomm: toWwwLinkEnv(envFlags),
    studio: toStudioLinkEnv(envFlags),
    www: toWwwLinkEnv(envFlags),
  }),
};

const errorHandler = configureErrorHandler([consoleErrorReporter]);

const isDark = envFlags.isUat || envFlags.isLocal;
const isLocal = envFlags.isLocal;
const isProd = envFlags.isProd;

const computedToggles = computeToggles(features[locale], isDark, isLocal);

const queryToggles = getQueryToggles(isDark, isLocal, document.location.search);

const { segment: segmentKey, ...vendorKeys } = getKeys(envFlags);

const reduxStore = toStore({
  initialState: {
    extLinkEnv: env.extLinkEnv,
    env: {
      appName: 'studio',
      flags: envFlags,
      locale,
    },
    toggles: {
      status: Status.Loaded,
      toggles: {
        ...computedToggles,
        ...queryToggles,
      },
    },
    vendorKeys,
  },
  reducers: {
    chat: chatReducer,
    env: envReducer,
    extLinkEnv: extLinkEnvReducer,
    toggles: toggles,
    user: userReducer,
    vendorKeys: vendorKeysReducer,
  },
  sagas: [analyticsSaga, authSaga, driftSagas, featureToggleSagas],
  context: {
    [API_CLIENT]: client,
    [ERROR_REPORTING_CLIENT_CONTEXT]: errorHandler.reportError,
  },
});

if (segmentKey) {
  loadAnalytics({ key: segmentKey }, makeAnalytics());
  // Track Studio Site App Viewed on page load
  track({ event: 'Loaded Studio Site App' });
} else {
  loadAnalytics({ key: '' }, NullAnalytics);
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    __REACT_DEVTOOLS_GLOBAL_HOOK__?: Record<string, Map<any, any> | (() => any) | null>;
  }
}

const graphQLClient = toGraphQLClient({
  env: env.extLinkEnv,
  fragmentMatcher,
  name: 'studio',
});

const toAuthorizationParams = (redirectUri: string) => ({
  authorizationParams: {
    redirect_uri: redirectUri,
  },
});

export const App: React.FC<React.PropsWithChildren<unknown>> = () => (
  <DatadogRUMContextProvider>
    <ErrorBoundary
      renderError={() => (
        <>
          <GlobalStyles />
          <SimpleErrorPage />
        </>
      )}
      reportError={errorHandler.reportError}
    >
      {/* prevent search engine indexing on non-production pages */}
      {!envFlags.isProd && <NoIndexMetadataTag />}
      <ErrorReporterContext.Provider value={{ errorReporter: errorHandler }}>
        <AsyncFallback
          ErrorFallback={<SimpleErrorPage />}
          LoadingFallback={<PageSpinner />}
        >
          <GlobalStyles />
          <ChatStyles />
          <OauthProvider
            {...toAuthorizationParams(window.location.origin + '/callback')}
            domain={process.env.STUDIO_AUTH_DOMAIN!}
            clientId={process.env.STUDIO_AUTH_CLIENT_ID!}
            onRedirectCallback={async () => {}}
            authEnv={globalLogoutEnv}
          >
            <ReduxProvider store={reduxStore} context={ReactReduxContext}>
              <Optimizely locale={locale} isProd={envFlags.isProd}>
                <ClientProvider client={client}>
                  <LocaleProvider envLocale={locale}>
                    <Internationalize>
                      <GraphQLProvider client={graphQLClient}>
                        <ApolloProviderV3 useApolloV3={true} locale={locale}>
                          <ConnectedRouter history={history} context={ReactReduxContext}>
                            <ErrorBoundary renderError={() => <ErrorPage />}>
                              {!envFlags.isProd && <CopyXray />}
                              <LogoutCookieProvider>
                                <ChatbotLayer locale={locale}>
                                  <ScrollToTop>
                                    <Routes root={routeTree} />
                                  </ScrollToTop>
                                </ChatbotLayer>
                              </LogoutCookieProvider>
                            </ErrorBoundary>
                          </ConnectedRouter>
                          <FeatureTogglePanel />
                        </ApolloProviderV3>
                      </GraphQLProvider>
                    </Internationalize>
                  </LocaleProvider>
                </ClientProvider>
              </Optimizely>
            </ReduxProvider>
          </OauthProvider>
        </AsyncFallback>
      </ErrorReporterContext.Provider>
    </ErrorBoundary>
  </DatadogRUMContextProvider>
);

if (isProd && typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === 'renders') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
      continue;
    }
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function') {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = function () {};
    } else {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = null;
    }
  }
}

if (process.env.NODE_ENV !== 'test') {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(<App />);
}

reportFocusMethod(document);
